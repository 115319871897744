export function useSlug() {
  const { params } = useRoute();

  const slug = computed(() =>
    params.slug
      ? typeof params.slug === "string"
        ? params.slug
        : stripTrailingSlash(params.slug.join("/"))
      : "home",
  );

  return {
    slug,
  };
}
